/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Me he hecho una cuenta de identi.ca, no se como acabará ésto. ", React.createElement(_components.a, {
    href: "http://identi.ca",
    title: "identi.ca"
  }, "Identi.ca"), " es una especie de twitter basado en software libre que soporta la descentralización, además puedes entrar con tu cuenta ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2010/02/22/openid/",
    title: "OpenID"
  }, "OpenID"), " y conectar con otras redes como Facebook o twitter, no se si esto me va a ser útil o no servirá para nada, por ahora tengo un RSS a mi blog en la barra, con solo un comentario de prueba."), "\n", React.createElement(_components.p, null, "Si quereis suscribiros: ", React.createElement(_components.a, {
    href: "http://identi.ca/litox9"
  }, "http://identi.ca/litox9")), "\n", React.createElement(_components.p, null, "Saludos"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
